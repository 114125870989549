import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      errorImg: file(relativePath: { eq: "error.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero pageTitle="Hmmm..." />

      <section className="section-1 highlights image-center">
        <div className="container smaller">
          <div className="row text-center intro">
            <div className="col-12">
              <h2>
                404:{" "}
                <span className="featured">
                  <span>Not Found</span>
                </span>
              </h2>
              <div className="py-5">
                <Img fluid={data.errorImg.childImageSharp.fluid} />
              </div>

              <p className="text-max-800">
                There doesn&#39;t appear to be anything here...
              </p>
              <Link to="/">Back to homepage</Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
